import { Box, Container, styled } from "@mui/material";
import NextImage from "next/image";
import Wood from "../Icons/Wood/Wood";
import { EditedBlockFragment } from "./Editedblock.gql";

const Image = styled(NextImage)({});

export default function EditedBlock(props: EditedBlockFragment) {
  const { blocs } = props;
  const htmlTitle = blocs?.[0]?.title;
  const htmlContent = blocs?.[0]?.content;
  return (
    <Container
      sx={(theme) => ({
        display: "flex",
        flexDirection: blocs?.[0]?.position === "RIGHT" ? "row" : "row-reverse",
        gap: 5,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "max(80px, min((53.33px + 8.33vw), 160px))",
        [theme.breakpoints.down("md")]: {
          flexWrap: "wrap",
        },
      })}
    >
      {htmlTitle && htmlContent && (
        <Box sx={{ width: "800px", position: "relative" }}>
          <Wood
            sx={{
              width: "177.8px",
              height: "175.3px",
              position: "absolute",
              top: 20,
              left: -10,
            }}
          />
          <Box
            sx={{ lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: htmlTitle as string }}
          />
          <Box
            sx={{ lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: htmlContent as string }}
          />
        </Box>
      )}
      {blocs?.[0]?.url && (
        <Box sx={{ position: "relative" }}>
          <Wood
            sx={(theme) => ({
              width: "177.8px",
              height: "175.3px",
              position: "absolute",
              display: "none",
              [theme.breakpoints.up("md")]: {
                bottom: 200,
                right: -80,
                display: "block",
              },
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            })}
          />
          <Image
            src={blocs?.[0]?.url}
            width={513}
            height={690}
            alt="Wood cut"
            sx={(theme) => ({
              maxWidth: "100%",
              borderRadius: "8px",
              height: "auto",
              [theme.breakpoints.up("md")]: { transform: "translateY(75px)" },
            })}
          />
          <Wood
            sx={(theme) => ({
              width: "243px",
              height: "239px",
              position: "absolute",
              display: "none",
              [theme.breakpoints.up("md")]: {
                bottom: -100,
                left: -80,
                display: "block",
              },
            })}
          />
        </Box>
      )}
    </Container>
  );
}
