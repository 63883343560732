import { JsonLd } from "./JsonLd";

export function BreadCrumbListJsonLd(props) {
  const { breadcrumbs, name, url_path, cmsPage } = props;
  const canonicalBaseUrl = import.meta.graphCommerce.canonicalBaseUrl;
  const fullBreadcrumbs = [
    { category_name: "Négoce bois", category_url_path: "" },
    ...(breadcrumbs || []),
  ];

  if (cmsPage) {
    fullBreadcrumbs.push({
      category_name: cmsPage.title,
      category_url_path: cmsPage.url_key,
    });
  }

  if (name && url_path) {
    fullBreadcrumbs.push({ category_name: name, category_url_path: url_path });
  }

  const itemListElement = fullBreadcrumbs.map(
    ({ category_name, category_url_path }, i) => ({
      "@type": "ListItem",
      position: i + 1,
      name: category_name,
      item: `${canonicalBaseUrl}/${category_url_path}`,
    }),
  );

  return (
    <JsonLd
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement,
      }}
    />
  );
}
