import CategoryBlockList from "@/components/smbois/CategoryBlockList/CategoryBlockList";
import EditedBlock from "@/components/smbois/Edito/EditedBlock";
import { RowHeroBanner } from "@/components/smbois/HeroBanner/RowHeroBanner";
import {
  LayoutNavigation,
  LayoutNavigationProps,
} from "@/components/smbois/Layout";
import { LayoutDocument } from "@/components/smbois/Layout/Layout.gql";
import { Grid } from "@/components/smbois/Product";
import {
  TestimonialsQuery,
  TestimonialsQueryDocument,
} from "@/components/smbois/Testimonials/Testimonials.gql";
import TestimonialsLayout from "@/components/smbois/Testimonials/TestimonialsLayout";
import {
  DefaultPageDocument,
  DefaultPageQuery,
} from "@/graphql/DefaultPage.gql";
import {
  HomeCategoryProductDocument,
  HomeCategoryProductQuery,
} from "@/graphql/HomeCategoryProduct.gql";
import {
  graphqlSharedClient,
  graphqlSsrClient,
} from "@/lib/graphql/graphqlSsrClient";
import { PageOptions } from "@graphcommerce/framer-next-pages";
import { ProductListQuery } from "@graphcommerce/magento-product";
import { StoreConfigDocument } from "@graphcommerce/magento-store";
import { GetStaticProps, PageMeta } from "@graphcommerce/next-ui";
import { BreadCrumbListJsonLd } from "../components/smbois/JsonLd/BreadCrumbListJsonLd";
import { LocalBusinessJsonLd } from "../components/smbois/JsonLd/LocalBusinessJsonLd";
import { WebsiteJsonLd } from "../components/smbois/JsonLd/WebsiteJsonLd";
import {
  LocalBusinessJsonLdInfoDocument,
  LocalBusinessJsonLdInfoQuery,
} from "../graphql/LocalBusinessJsonLdInfo.gql";
import { ProductList2Document } from "../graphql/ProductList2.gql";
import { getRevalidationInterval } from "../utils/deploy";

type Props = DefaultPageQuery & {
  testimonials: TestimonialsQuery;
  promotions: ProductListQuery;
  localBusinessJsonLdInfo: LocalBusinessJsonLdInfoQuery;
  homeCategoryProducts: HomeCategoryProductQuery;
};
type RouteProps = { url: string };
type GetPageStaticProps = GetStaticProps<
  LayoutNavigationProps,
  Props,
  RouteProps
>;

function CmsPage(props: Props) {
  const {
    cmsPage,
    testimonials,
    rowHeroBanners,
    blocs,
    categoryLinkBlock,
    localBusinessJsonLdInfo,
    homeCategoryProducts,
  } = props;
  const page = cmsPage;
  const homeCategory = homeCategoryProducts.categories?.items;
  return (
    <>
      <BreadCrumbListJsonLd />
      <LocalBusinessJsonLd {...localBusinessJsonLdInfo.storeConfig} />
      <WebsiteJsonLd />
      <PageMeta
        title={page?.meta_title ?? page?.title ?? ""}
        metaDescription={page?.meta_description ?? ""}
        canonical="/"
      />

      <RowHeroBanner
        rowHeroBanners={rowHeroBanners}
        {...rowHeroBanners?.products}
      />

      {homeCategory?.map((category, index) => (
        <Grid
          key={index}
          title={category?.name as string}
          href={category?.url_path as string}
          {...category?.products}
        />
      ))}

      <EditedBlock blocs={blocs} />
      <CategoryBlockList categoryLinkBlock={categoryLinkBlock} />
      <TestimonialsLayout testimonials={testimonials} />
    </>
  );
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions;

export default CmsPage;

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale);
  const staticClient = graphqlSsrClient(locale);

  const conf = client.query({ query: StoreConfigDocument });
  const page = staticClient.query({
    query: DefaultPageDocument,
    variables: { urlKey: `home` },
  });
  const layout = staticClient.query({
    query: LayoutDocument,
  });

  const homeCategoryProducts = staticClient.query({
    query: HomeCategoryProductDocument,
    variables: { showcase: "1", sort: { position: "ASC" } },
  });

  const promotion = staticClient.query({
    query: ProductList2Document,
    variables: {
      pageSize: 3,
      filters: { category_uid: { eq: "MTIx" } },
      sort: { position: "ASC" },
    },
  });

  const testimonials = staticClient.query({
    query: TestimonialsQueryDocument,
  });

  const localBusinessJsonLdInfo = staticClient.query({
    query: LocalBusinessJsonLdInfoDocument,
  });

  if (!(await page).data) return { notFound: true };

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      localBusinessJsonLdInfo: (await localBusinessJsonLdInfo).data,
      promotions: (await promotion).data,
      homeCategoryProducts: (await homeCategoryProducts).data,
      testimonials: (await testimonials).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: getRevalidationInterval(),
  };
};
