import { JsonLd } from "./JsonLd";

export function WebsiteJsonLd() {
  const canonicalBaseUrl = import.meta.graphCommerce.canonicalBaseUrl;

  // Sitelinks search box
  return (
    <JsonLd
      item={{
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: canonicalBaseUrl,
        potentialAction: {
          "@type": "SearchAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: `${canonicalBaseUrl}/search/{search_term_string}`,
          },
          "query-input": "required name=search_term_string",
        },
      }}
    />
  );
}
