import { Box } from "@mui/material/";
import React from "react";

type SliderProps = {
  children: React.ReactNode;
};
export default function Slider(props: SliderProps) {
  const { children } = props;
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        padding: "2px",
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up("md")]: {
          background: theme.palette.background.default,
        },
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        overflow: "auto",
        width: "auto",
        height: "100%",
      })}
    >
      {children}
    </Box>
  );
}
