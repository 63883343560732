import { ContainerWithHeader } from "@graphcommerce/next-ui";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import Slider from "../Slider/Slider";
import {
  ProductListItems,
  ProductListItemsProps,
} from "./ProductListItems/ProductListItems";

type GridProps = ProductListItemsProps & { href?: string } & {
  isSlider?: boolean;
};

export function Grid(props: GridProps) {
  const { title, href, isSlider = true, ...productListItems } = props;
  const theme = useTheme();
  const displaySlider =
    useMediaQuery(theme.breakpoints.down("md")) &&
    (productListItems.items?.length || 0) > 1 &&
    isSlider;

  const StyledProductListItems = ({ sx = {} }) => (
    <ProductListItems
      title={title}
      {...productListItems}
      size="small"
      titleComponent="h3"
      sx={{ display: "grid", ...sx }}
    />
  );

  return (
    <ContainerWithHeader
      title={title}
      rightArea={
        href && (
          <Link color="inherit" href={href} key={href} underline="always">
            voir tout
          </Link>
        )
      }
      sx={{ ".ContainerWithHeader-head": { alignItems: "center" } }}
    >
      {displaySlider ? (
        <Slider>
          <StyledProductListItems sx={{ gridAutoFlow: "column" }} />
        </Slider>
      ) : (
        <StyledProductListItems />
      )}
    </ContainerWithHeader>
  );
}
