import {
  extendableComponent,
  responsiveVal,
  Row,
} from "@graphcommerce/next-ui";
import { Box, ContainerProps, SxProps, Theme } from "@mui/material";
import Image from "next/image";
import React from "react";

export type HeroBannerProps = ContainerProps & {
  pageLinks: React.ReactNode;
  imgUrl: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const compName = "HeroBanner" as const;
const parts = [
  "root",
  "wrapper",
  "copy",
  "asset",
  "animated",
  "image",
] as const;
const { classes } = extendableComponent(compName, parts);

export function HeroBanner(props: HeroBannerProps) {
  const { pageLinks, imgUrl, children, sx = [], ...containerProps } = props;
  return (
    <Row
      maxWidth={false}
      {...containerProps}
      className={classes.root}
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Box
        className={classes.wrapper}
        sx={(theme) => ({
          display: "grid",
          overflow: "hidden",
          borderRadius: responsiveVal(
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
          ),
          isolation: "isolate",
        })}
      >
        <Box
          className={classes.copy}
          sx={(theme) => ({
            gridArea: "1 / 1",
            zIndex: 1,
            display: "grid",
            justifyItems: "center",
            alignContent: "center",
            textAlign: "center",
            transform: "translateY(-80px)",
            color: theme.palette.secondary.contrastText,
            width: "100% !important",
            [theme.breakpoints.down("md")]: {
              width: "50%",
            },
          })}
        >
          {children}
          {pageLinks}
        </Box>
        <Box
          className={classes.asset}
          sx={{
            gridArea: "1 / 1",
            position: "relative",
            width: "100%",
            height: "800px",
          }}
        >
          <Image
            blurDataURL={imgUrl}
            src={imgUrl}
            loading="eager"
            fill
            alt="Banner"
            style={{ objectFit: "cover" }}
            placeholder="blur"
            priority={true}
          />
        </Box>
      </Box>
    </Row>
  );
}
