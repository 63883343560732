import { Box, Stack, Typography, useTheme } from "@mui/material";
import RedQuotes from "../Icons/RedQuotes";

// eslint-disable-next-line no-unused-vars
enum TestimonialsVariants {
  // eslint-disable-next-line no-unused-vars
  LARGE = "large",
  // eslint-disable-next-line no-unused-vars
  MEDIUM = "medium",
  // eslint-disable-next-line no-unused-vars
  MOBILE = "mobile",
  // eslint-disable-next-line no-unused-vars
  REVIEW = "review",
}

type TestimonialProps = {
  image?: string;
  testimonial?: any;
  variant?: string;
};
export default function Testimonial(props: TestimonialProps) {
  const { variant, testimonial, image } = props;
  const theme = useTheme();

  if (!testimonial) return null;
  return (
    <>
      {variant === TestimonialsVariants.MEDIUM && (
        <Box
          sx={{
            minHeight: "300px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            marginLeft: "30px",
            padding: "32px",
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: "0px 4px 24px 0px rgba(122, 122, 122, 0.15)",
          }}
        >
          <Stack
            sx={{
              width: "14px",
              height: "16px",
              marginRight: "16px",
            }}
          >
            <RedQuotes
              sx={{
                width: "14px",
                height: "16px",
              }}
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                color: theme.palette.text.primary,
              }}
            >
              {testimonial.message}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "48px",
                marginTop: "24px",
              }}
            >
              {testimonial.senderName}, {testimonial.senderInfo}
            </Typography>
          </Stack>
        </Box>
      )}
      {variant === TestimonialsVariants.LARGE && (
        <Box
          sx={{
            minHeight: "280px",
            maxWidth: "550px",
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            marginLeft: "30px",
            padding: "32px",
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: "0px 4px 24px 0px rgba(122, 122, 122, 0.15)",
          }}
        >
          <Stack
            sx={{
              width: "14px",
              height: "16px",
              marginRight: "16px",
            }}
          >
            <RedQuotes
              sx={{
                width: "14px",
                height: "16px",
              }}
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", lineHeight: "24px", color: "black" }}
            >
              {testimonial.message}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "48px",
                marginTop: "24px",
              }}
            >
              {testimonial.senderName}, {testimonial.senderInfo}
            </Typography>
          </Stack>
        </Box>
      )}
      {variant === TestimonialsVariants.MOBILE && (
        <Box
          sx={{
            minHeight: "257px",
            minWidth: "200px",
            marginTop: "30px",
            padding: "32px 32px 32px 20px",
            display: "flex",
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: "0px 4px 24px 0px rgba(122, 122, 122, 0.15)",
            marginBottom: "50px",
          }}
        >
          <Box
            sx={{
              width: "14px",
              height: "16px",
              marginRight: "16px",
              transform: "translateX(-10px)",
            }}
          >
            <RedQuotes
              sx={{
                width: "14px",
                height: "16px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                color: theme.palette.text.primary,
              }}
            >
              {testimonial.message}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "48px",
                marginTop: "10px",
                fontSize: "10px",
              }}
            >
              {testimonial.senderName}, {testimonial.senderInfo}
            </Typography>
          </Box>
        </Box>
      )}
      {variant === TestimonialsVariants.REVIEW && (
        <>
          <Box
            sx={{
              minHeight: "80px",
              height: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              margin: "30px 0 30px 30px",
              padding: "32px",
              borderRadius: "8px",
              backgroundColor: "white",
              boxShadow: "0px 4px 24px 0px rgba(122, 122, 122, 0.15)",
            }}
          >
            <Stack
              sx={{
                width: "14px",
                height: "16px",
                marginRight: "16px",
              }}
            >
              <RedQuotes
                sx={{
                  width: "14px",
                  height: "16px",
                }}
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "black",
                }}
              >
                {testimonial?.text}
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {image && (
                  <Box
                    component="img"
                    src={image || "#"}
                    sx={{
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: "48px",
                    marginTop: "24px",
                  }}
                >
                  {testimonial?.nickname}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}
