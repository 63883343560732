import { JsonLd } from "./JsonLd";

export function LocalBusinessJsonLd(props) {
  const {
    storeName,
    storeCity,
    storeStreetLine1,
    storeStreetLine2,
    storePhone,
    storePostcode,
    secure_base_media_url,
    header_logo_src,
  } = props;
  const canonicalBaseUrl = import.meta.graphCommerce.canonicalBaseUrl;

  return (
    <JsonLd
      item={{
        "@context": "https://schema.org",
        "@type": "Store",
        image: [`${secure_base_media_url}logo/${header_logo_src}`],
        name: storeName,
        address: {
          "@type": "PostalAddress",
          streetAddress: `${storeStreetLine1} ${storeStreetLine2}`,
          addressLocality: storeCity,
          addressRegion: "Ile-de-France",
          postalCode: storePostcode,
          addressCountry: "FR",
        },
        url: canonicalBaseUrl,
        priceRange: "$",
        telephone: storePhone,
      }}
    />
  );
}
