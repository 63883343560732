import { safeJsonLdReplacer } from "@graphcommerce/next-ui/JsonLd/safeJsonLdReplacer";
import Head from "next/head";

export function JsonLd<T extends { "@type": string }>(props: {
  item: T & { "@context": "https://schema.org" };
}) {
  const { item } = props;

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(item, safeJsonLdReplacer),
        }}
      />
    </Head>
  );
}
