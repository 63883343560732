import { HeroBanner } from "@/components/smbois/HeroBanner/HeroBanner";
import { breakpointVal } from "@graphcommerce/next-ui";
import { Box, Button, Container } from "@mui/material";
import {
  ProductListItems,
  ProductListItemsProps,
} from "../Product/ProductListItems";
import Slider from "../Slider/Slider";
import { RowHeroBannerFragment } from "./RowHeroBannerFragment.gql";

type RowHeroBannerProps = Omit<ProductListItemsProps, "title"> &
  RowHeroBannerFragment;

export function RowHeroBanner(props: RowHeroBannerProps) {
  const { rowHeroBanners } = props;
  const listItems = {
    items: rowHeroBanners?.products,
    bundle: true,
  };
  return (
    <Box>
      <HeroBanner
        pageLinks={
          <Button
            href={rowHeroBanners?.link || "/"}
            target={rowHeroBanners?.target || "_self"}
            variant="primary"
            size="large"
          >
            {rowHeroBanners?.label}
          </Button>
        }
        imgUrl={rowHeroBanners?.image || ""}
        sx={(theme) => ({
          "& .HeroBanner-copy": {
            minHeight: { xs: "min(70vh,600px)", md: "min(70vh,1080px)" },
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacings.xl,
              justifyItems: "start",
              alignContent: "center",
              textAlign: "left",
              width: "50%",
            },
          },
          marginBottom: "0px",
        })}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: rowHeroBanners?.title || "" }}
          sx={(theme) => ({
            ...breakpointVal("fontSize", 32, 45, theme.breakpoints.values),
            mb: "1em",
            lineHeight: 1,
          })}
        />
      </HeroBanner>
      <Container
        sx={{ transform: "translateY(-150px)", maxWidth: "1200px !important" }}
      >
        <Box
          sx={(theme) => ({
            display: "block",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <Slider>
            <ProductListItems
              title="Promotions"
              {...listItems}
              size="small"
              titleComponent="h3"
              sx={() => ({
                display: "-webkit-box",
                ".ProductListItem-root": {
                  "@supports (-webkit-touch-callout: none)": {
                    /* CSS specific to iOS devices */
                    mr: "20px",
                  },
                },
                ".MuiButtonBase-root": {
                  "&.MuiChip-root": { display: "none" },
                  boxShadow: "6px 6px 24px 0px rgba(0, 0, 0, 0.10)",
                  ".ProductListItem-titleContainer": {
                    width: "300px",
                    padding: "0px 15px 15px 15px",
                    ".MuiBox-root": {
                      ":first-of-type": {
                        ".MuiTypography-root": {
                          fontSize: "11px",
                        },
                      },
                      ".MuiBox-root": {
                        ".MuiTypography-root": {
                          fontSize: "10px",
                        },
                      },
                    },
                  },
                },
                ".ProductListItem-imageContainer": {
                  border: "none",
                },
              })}
            />
          </Slider>
        </Box>
        <Box
          sx={(theme) => ({
            display: "none",
            [theme.breakpoints.up("md")]: {
              display: "block",
            },
          })}
        >
          <ProductListItems
            title="Promotions"
            {...listItems}
            size="small"
            titleComponent="h3"
            sx={{
              ".MuiButtonBase-root": {
                "&.MuiChip-root": { display: "none" },
                boxShadow: "6px 6px 24px 0px rgba(0, 0, 0, 0.10)",
                ".ProductListItem-titleContainer": {
                  padding: "0px 15px 15px 15px",
                },
                ".ProductListItem-imageContainer": {
                  border: "none",
                },
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
