import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import { GraphQLNonNull } from "graphql";
import Image from "next/image";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import RedQuotes from "../Icons/RedQuotes";
import Testimonial from "./Testimonial";
import { TestimonialsQuery } from "./Testimonials.gql";

type TestimonialsLayoutProps = {
  testimonials: TestimonialsQuery;
};
export default function TestimonialsLayout(props: TestimonialsLayoutProps) {
  const { testimonials } = props;
  const theme = useTheme();
  const slicedTestimonials = testimonials?.testimonials?.slice(0, 3);
  return (
    <>
      <Container
        sx={() => ({
          width: "100%",
          minHeight: "600px",
          height: "auto",
          position: "relative",
          overflowX: "hidden !important",
          overflowY: "hidden",
          [theme.breakpoints.up("md")]: {
            minHeight: "1000px",
          },
        })}
      >
        <Container
          sx={{
            width: "100%",
            height: "auto",
            position: "relative",
            zIndex: "10",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Stack
                sx={{
                  width: "143px",
                  height: "120px",
                  marginRight: "-22px",
                }}
              >
                <RedQuotes
                  sx={{
                    width: "143px",
                    height: "120px",
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  paddingTop: "73px",
                }}
              >
                <Stack
                  sx={{
                    display: { sm: "flex", md: "none" },
                    height: "auto",
                    flexDirection: "column",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    alignItems: "flex-end",
                    marginLeft: "-90px",
                    paddingTop: "33px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "48px",
                      color: theme.palette.text.primary,
                      height: "50px",
                    }}
                  >
                    NOS CLIENTS
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "48px",
                      height: "50px",
                    }}
                  >
                    TÉMOIGNENT
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: "48px",
                    display: { xs: "none", sm: "none", md: "flex" },
                    height: "auto",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  NOS CLIENTS{"  "}
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: "8px",
                    }}
                  >
                    {"  "}TÉMOIGNENT
                  </span>
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                display: {
                  sm: "flex",
                  md: "none",
                  overflowX: "hidden !important",
                },
                flexDirection: "row",
              }}
            >
              <Swiper
                direction={"horizontal"}
                slidesPerView={1.2}
                spaceBetween={20}
                freeMode={true}
                mousewheel={true}
              >
                {slicedTestimonials?.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <Testimonial testimonial={testimonial} variant="mobile" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Stack>
            <Stack
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                flexDirection: "column",
                paddingTop: "59px",
              }}
            >
              <Testimonial
                testimonial={
                  testimonials?.testimonials !== undefined &&
                  testimonials?.testimonials !== null
                    ? testimonials?.testimonials[0]
                    : GraphQLNonNull
                }
                variant="medium"
              />
            </Stack>
            <Stack
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                flexDirection: "column",
                height: "auto",
              }}
            >
              <Testimonial
                testimonial={
                  testimonials?.testimonials !== undefined &&
                  testimonials?.testimonials !== null
                    ? testimonials?.testimonials[1]
                    : null
                }
                variant="large"
              />
              <Testimonial
                testimonial={
                  testimonials?.testimonials !== undefined &&
                  testimonials?.testimonials !== null
                    ? testimonials?.testimonials[2]
                    : null
                }
                variant="medium"
              />
            </Stack>
          </Stack>
        </Container>
        <Box
          component="div"
          sx={(theme) => ({
            position: "absolute",
            top: "200px",
            left: "-35%",
            width: "576.12px",
            height: "578px",
            flexShrink: 0,
            borderRadius: "578px",
            zIndex: 0,
            overflow: "hidden !important",
            display: "block",
            [theme.breakpoints.up("md")]: {
              top: "250px",
              left: "15%",
              display: "none",
            },
          })}
        >
          <Image
            blurDataURL={`/assets/blurImage.webp`}
            src={`/assets/blurImage.webp`}
            loading="eager"
            fill
            alt="blur"
            style={{
              objectFit: "cover",
              boxShadow: "0 0 20px 20px rgba(255, 255, 255, 0.8) inset",
              borderRadius: "578px",
              transform: "rotate(90deg)",
            }}
            placeholder="blur"
          />
        </Box>
        <Box
          component="div"
          sx={(theme) => ({
            position: "absolute",
            top: "200px",
            left: "-35%",
            width: "576.12px",
            height: "578px",
            filter: "blur(150px)",
            transform: "rotate(90deg)",
            flexShrink: 0,
            borderRadius: "578px",
            background:
              "linear-gradient(328deg, rgba(255, 172, 172, 0.00) 29.53%, rgba(241, 159, 159, 0.26) 37.05%, rgba(255, 208, 208, 0.79) 48.92%, #FFD6C9 62.56%)",
            zIndex: 0,
            overflow: "hidden !important",
            display: "none",
            [theme.breakpoints.up("md")]: {
              top: "250px",
              left: "15%",
              display: "block",
            },
          })}
        />
      </Container>
    </>
  );
}
