import BookmarksWood from "@/components/smbois/Icons/Wood/BookmarksWood";
import ChalkboardWood from "@/components/smbois/Icons/Wood/ChalkboardWood";
import CubeWood from "@/components/smbois/Icons/Wood/CubeWood";
import HardDrivesWood from "@/components/smbois/Icons/Wood/HardDrivesWood";
import HouseLineWood from "@/components/smbois/Icons/Wood/HouseLineWood";
import StackWood from "@/components/smbois/Icons/Wood/StackWood";
import TableWood from "@/components/smbois/Icons/Wood/TableWood";
import TagWood from "@/components/smbois/Icons/Wood/TagWood";
import { Box, Container, Link, Typography } from "@mui/material";
import Wood from "../Icons/Wood/Wood";
import { categoryLinkBlockFragment } from "./CategoryLinkBlock.gql";

export default function CategoryBlockList(props: categoryLinkBlockFragment) {
  const { categoryLinkBlock } = props;
  const icon = {
    bookmarks: (
      <BookmarksWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    chalkboard: (
      <ChalkboardWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    cube: (
      <CubeWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    hardDrives: (
      <HardDrivesWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    houseLine: (
      <HouseLineWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    stack: (
      <StackWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    table: (
      <TableWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
    tag: (
      <TagWood
        sx={(theme) => ({
          width: "34px",
          height: "34px",
          [theme.breakpoints.up("md")]: {
            width: "70px",
            height: "69px",
          },
        })}
      />
    ),
  };

  return (
    <Container
      sx={() => ({
        position: "relative",
        marginBottom: "max(80px, min((53.34px + 8.33vw), 160px))",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexWrap: "wrap",
          gap: "4.2px",
          zIndex: 2,
          justifyContent: "center",
          position: "relative",
          width: "100%",
          backgroundColor: "transparent",
          [theme.breakpoints.up("md")]: {
            gap: "30px",
            justifyContent: "center",
          },
        })}
      >
        {categoryLinkBlock?.map((category, index) => (
          <Link
            href={category?.url || "#"}
            key={index}
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.15)",
              backgroundColor: "white",
              gap: "8px",
              zIndex: 1,
              borderRadius: "12px",
              padding: 0.5,
              color: "transparent",
              cursor: "pointer",
              width: "167px",
              height: "72px",
              textDecoration: "none",
              [theme.breakpoints.up("md")]: {
                width: "269px",
                height: "108px",
                gap: "19px",
              },
              ":hover": {
                textDecoration: "none",
                color: "transparent",
              },
            })}
          >
            {icon[category?.iconName || "#"]}
            <Typography
              component="h3"
              sx={(theme) => ({
                width: "134px",
                color: "text.primary",
                lineHeight: "normal",
                fontSize: "13px",
                [theme.breakpoints.up("md")]: {
                  fontSize: "16px",
                },
              })}
            >
              {category?.categoryName}
            </Typography>
          </Link>
        ))}
      </Box>
      <Wood
        sx={(theme) => ({
          width: "243px",
          height: "239px",
          position: "absolute",
          zIndex: 1,
          top: -150,
          right: 0,
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      />
    </Container>
  );
}
